/* eslint-disable */
var envConfig = require('../app/config/envs');
var Raven = require('raven-js');

module.export = (function errorTracker() {
  try {
    var dsn = envConfig.default.sentryKey;
    // do not track local errors
    if (process.env.NODE_ENV !== 'local') {
      Raven.config(dsn, {
        environment: process.env.NODE_ENV,
      }).install();
    }
  } catch (err) {}
})();
